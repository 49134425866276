import React from 'react'
import { Link, graphql } from 'gatsby'

import Seo from '../components/seo'
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'

const FencePage = props => {
  const videoData = props.data.videoData
  return (
    <Layout>
      <div className='bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-4 mt-8 max-w-6xl mx-auto '>
        <ReactMarkdown
          children={props?.data?.markdownRemark?.frontmatter?.content}
          components={{
            p: ({ children }) => {
              return (
                <p className='text-sm text-gray-700 font-light max-w-full mb-3 leading-6'>
                  {children}
                </p>
              )
            },
            h2: ({ children }) => {
              return (
                <h2 className='text-3xl text-gray-600 uppercase font-light title pb-5 mt-7 text-center mb-10 '>
                  {children}
                </h2>
              )
            },
            h3: ({ children }) => {
              return (
                <h3 className='md:text-2xl text-xl text-gray-600 uppercase  mt-5 font-medium title pb-5 mt-7 text-center mb-10 '>
                  {children}
                </h3>
              )
            },
            h4: ({ children }) => {
              return (
                <h4 className='text-2xl font-bold font3 mb-4'>{children}</h4>
              )
            },
            ul: ({ children }) => {
              return (
                <ul className='md:mb-16 mb-6 pl-2 list-disc'>{children}</ul>
              )
            },
            li: ({ children }) => {
              return <li className='text-sm font-normal mb-3'>{children}</li>
            }
          }}
        />
        <div className='grid md:grid-cols-3 md:gap-6'>
          {videoData &&
            videoData.edges.map((item, index) => {
              return (
                <div className='w-full float-left bg-gray-200 p-5 border-b md:mb-7 mb-8 border-gray-700'>
                  <iframe
                    className='mb-5'
                    width='100%'
                    height='150'
                    src={item.node.link}
                    title='YouTube video player'
                    frameborder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                    allowfullscreen
                  />
                  <h3 className='w-full text-center text-gray-500 uppercase text-xl no-underline'>
                    {item.node.name}
                  </h3>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}
export default FencePage

export const Head = props => (
  <Seo
    title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
    path={props.location.pathname}
  />
)
export const data = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "fence-installation-videos" } }) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
    videoData: allVideosJson {
      edges {
        node {
          name
          link
        }
      }
    }
  }
`
